import React from 'react'
import MintSection from '../components/mintsection/MintSection'
import Disclaimer from '../components/Disclaimer'

const Mint = () => {
  return (
    <div className="main-section">
    <MintSection/>
    <Disclaimer/>
    </div>
  )
}

export default Mint