import  { Fragment } from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/topbar/TopBar';
import MintSection from './components/mintsection/MintSection';
import CollectionSection from './components/colection/Collection';
import Dashboard from './pages/Dashboard';
import Mint from './pages/Mint';
import FirstContextProvider from './context/FirstContext.jsx'

function App() {
  return (
      <div className='dashBG'>
        <Fragment>
          <FirstContextProvider>
            <TopBar />
            <Routes>
              <Route path="/" element={<Mint/>} />
              {/* <Route path="/mint" element={<Dashboard/>} /> */}
            </Routes>
          </FirstContextProvider>
        </Fragment>
      </div>
  );
}

export default App;

