import React, { createContext, useState, useEffect } from 'react';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { providers, Contract, BigNumber } from 'ethers'
import axios from "axios";
import nftAbi from "../json/nftAbi.json"
/* global BigInt */

import proof1  from "../json/proofs/1.json";
import proof2  from "../json/proofs/2.json";
import proof3  from "../json/proofs/3.json";
import proof4  from "../json/proofs/4.json";
import proof5  from "../json/proofs/5.json";
import proof6  from "../json/proofs/6.json";
import proof7  from "../json/proofs/7.json";
import proof8  from "../json/proofs/8.json";
import proof9  from "../json/proofs/9.json";
import proof10  from "../json/proofs/10.json";

const nftAddress = "0x5907706cc619b8143a9ca57b9cf370a9a4b14f72";
// 1. Get projectId
const projectId = '68ab9ca8ddfabbbf4f2b599adac0824a'

const START_MINT = 1720188000
// const START_MINT = new Date() / 1000 + 5




// 2. Set chains
// const mainnet = {
//   chainId: 1,
//   name: 'Ethereum',
//   currency: 'ETH',
//   explorerUrl: 'https://etherscan.io',
//   rpcUrl: 'https://cloudflare-eth.com'
// }

// const allowedChains = [
//   "0x7a69", // localhost
//   "0x2105" // base
// ]

const allowedChains = [
  31337, // localhost
  8453, // base
]


const base = {
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://mainnet.base.org',
    rpcUrl: 'https://base.blockpi.network/v1/rpc/public'
  }

  const localhost = {
    chainId: 31337,
    name: 'LocalHost',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: '127.0.0.1:8545	'
  }

  // const localhost = {
  //   chainId: 31337,
  //   name: 'LocalHost',
  //   currency: 'ETH',
  //   explorerUrl: 'https://etherscan.io',
  // }

// 3. Create a metadata object
const metadata = {
  name: 'Satoshi',
  description: 'The Satoshi NFT',
  url: 'http://dapp.satoshitoken.org', // origin must match your domain & subdomain
  icons: ['http://dapp.satoshitoken.org/public/logo.png']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 8453 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [base],
  projectId,
  enableAnalytics: false // Optional - defaults to your Cloud configuration
})

export const FirstContext = createContext()


const proofs = [
  proof1,
  proof2,
  proof3,
  proof4,
  proof5,
  proof6,
  proof7,
  proof8,
  proof9,
  proof10
];


const getUserProof = user => {
  let proof = [];
  for(let i = 0; i < proofs.length; i++) {
    for(let j = 0; j < proofs[i].length; j++) {
      if(proofs[i][j]?.address?.toLowerCase() === user?.toLowerCase()){
        proof = proofs[i][j].proof;
      }
    }
  }

  return proof;

}

const FirstContextProvider = (props) => {
  const { open } = useWeb3Modal()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [backdrop, setBackdrop] = useState(false);
  const [supply, setSupply] = useState(118n);
  const [tx, setTx] = useState(0);
  const [amount, setAmount] = useState(1);

  const url = "https://api.satoshitoken.org/supply";
  const header = {
    "Content-Type": "application/json"
  };

  useEffect(()=>{
      axios.get(url, header).then(response => {
        setSupply(BigInt(parseInt(response.data.supply)));
      }).catch(error => {
        console.log(error.message)
      })
  }, [tx])


  const mint = async()=>{
    
    try {
      if(parseInt(Date.now()) / 1000 >= START_MINT) {
        if(isConnected && allowedChains.includes(chainId)) {
          const ethersProvider = new providers.Web3Provider(walletProvider);
          const signer = await ethersProvider.getSigner();
      
          // The Contract object
          const nftContract = new Contract(nftAddress, nftAbi, signer)
          const price = await nftContract.NFT_PRICE();
          
          setBackdrop(true)
          const tx = await nftContract.mint(amount, [], {value: BigNumber.from(amount).mul(price)});
          await tx.wait();
          setTx(tx + 1)
          setBackdrop(false)
        }
      }
    } catch(e) {
      setBackdrop(false)
      console.log(e.message)
    }
  }

  const checkSupply = async() => {
    try {
      if(isConnected && allowedChains.includes(chainId)) {
        const ethersProvider = new providers.Web3Provider(walletProvider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const nftContract = new Contract(nftAddress, nftAbi, signer)
        
        const totalSupply = await nftContract.totalSupply();
        setSupply(totalSupply);
      }
    } catch(e) {
      console.log(e.message)
    }
    

  }

  useEffect(()=>{
    checkSupply()
  }, [isConnected, tx])

  const shortedAddress = address => {
    const len = address.length
    const start = address.slice(0, 4)
    const end = address.slice(len - 4, len)
    return start + "..." + end
}


  return (
      <FirstContext.Provider value={{
        open,
        address,
        chainId,
        isConnected,
        walletProvider,
        mint,
        shortedAddress,
        backdrop,
        setBackdrop,
        supply,
        amount, 
        setAmount,
        START_MINT
      }}>
          {props.children}
      </FirstContext.Provider>
  )
}

export default FirstContextProvider;










