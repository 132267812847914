import { useEffect, useState, useContext } from 'react';
import logo from '../../images/logo.webp';
import HeadingMain from '../headingmain/HeadingMain';
import './topbar.scss';
import { Link } from 'react-router-dom';
import { FirstContext } from "../../context/FirstContext";
function TopBar() {

  const { 
    open,
    address,
    chainId,
    isConnected,
    walletProvider,
    mint,
    shortedAddress
  } = useContext(FirstContext);

  return (
    <div className='sticky '>
      <div className='d-flex w-100 justify-content-between mb-5'>
        { // <div className='d-flex m-0'>
          //     <img src={santoshi} alt='main img' className='main-img'></img>
          //     <p className='text-main'>SANTOSHI</p>
          // </div>
        }
        <div className='d-flex align-items-center gap-2'>
          <img src={logo} style={{ width: '75px' }} alt=""/>
          {/* <div className=' Miniature text-center text-black fw-bold ' style={{ fontSize: '20px' }}>
            This NFT collection is part of the Satoshi
            <br /> Nakamoto project.
          </div> */}
        </div>
        <div className='d-flex align-items-center justify-content-center gap-4'>
          <div className='d-flex gap-3 text-center'>
            {/* <Link className='top_link' to='/' >Dashboard</Link> 
                    <Link className='top_link' to='mint' >Mint</Link> */}
          </div>
          <button onClick={() => open()} className=' connect-wallet '>
            <span className="gradient-color">{isConnected ? shortedAddress(address) : "Connect Wallet"}</span>
          </button>
        </div>
      </div>
      {/* <HeadingMain /> */}

    </div>
  )
}

export default TopBar;