import React, { useState, useEffect, useContext } from 'react';
import '../topbar/topbar.scss';
import random from "../../images/random.png"
import {formatTimeDelta, calcTimeDelta} from 'react-countdown';
import { FirstContext } from "../../context/FirstContext";
import SimpleBackdrop from "../SimpleBackdrop"
import arrow from "../../images/r-arrow.png"
import DiscreteSlider from "../Slider"


function MintSection() {
    const {
        open,
        address,
        chainId,
        isConnected,
        walletProvider,
        mint,
        backdrop,
        setBackdrop,
        supply,
        amount, 
        setAmount,
        START_MINT
    } = useContext(FirstContext);

    const [res, setRes] = useState();
   
    useEffect(() => {
        if(parseInt(Date.now()) / 1000 < START_MINT) {
            //Implementing the setInterval method
            const interval = setInterval(() => {
                setRes(formatTimeDelta(calcTimeDelta(parseInt(START_MINT) * 1000)));
            }, 1000);
     
            //Clearing the interval
            return () => clearInterval(interval);
        }
    }, [res]);

    

    return (
        <div className="container d-flex flex-column mintCard bg miniature-container ">
        <div className='Nft-Sample'>
            <img src={random} alt=""></img>
        </div>
        <DiscreteSlider setter={setAmount}/>
        <button onClick={()=>mint()} className='mt-3 connect-wallet m-auto ' style={{width:'max-content'}}> <span className="gradient-color Miniature">Mint</span></button>
      
            <h2 className='minth2 text-center d-flex m-auto px-2 py-1 rounded-4 mt-2 text-white	 '>{amount} NFT{amount > 1 ? "'s":""} per {(amount * 0.1).toFixed(1)} ETH</h2>
            <h2 className='text-center text-white '><span className='Miniature'>NFT's Minted</span>{`: ${parseInt(supply)}/1000`}</h2>

            <div className='d-flex align-items-center gap-3 m-auto '>
            {/* <p className='a-text text-center text-white mint-font-26 align-middle'>Whitelist time remaining</p> */}
            {/* <img className="arrow" src={arrow} alt=""></img> */}
            {/* <svg className='svg-size' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path style={{fill:"#000"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right"/></svg> */}
            <div className="text-center">
                <div className="timer">
                
                    <span className="a-text align-middle anim-scale">Mint live 🚀</span>
            
                </div>
            </div>
            </div>
            <SimpleBackdrop
                open={backdrop}
                setOpen={setBackdrop}
            />
        </div>
    );
}




export default MintSection;
