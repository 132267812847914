function Disclaimer() {
    return (
        <div className="miniature-container m-auto text-justify">
            <p className='Miniature mt-5 text-white w-full'>
                <b>Disclaimer:</b><br />
                This project operates on the principles of decentralization and community-driven initiatives. Satoshi is a decentralized entity with no central owner or authority. As such, all funding and initiatives are solely contributed by active members of the community.

                It's important to recognize that participation in this project carries inherent risks, and individuals should conduct their own due diligence before engaging in any activities related to Satoshi. The decentralized nature of the project means that decisions and actions are determined by consensus among community members, and there is no guarantee of specific outcomes or returns.

                By participating in the Satoshi project, individuals acknowledge and accept the decentralized nature of the initiative, understanding that they are contributing to a community-driven endeavor without central ownership or control.</p>



        </div>
    )
}

export default Disclaimer;