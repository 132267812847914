import * as React from 'react';
import Slider from '@mui/material/Slider';


export default function DiscreteSlider({setter}) {
    function getValue(e, value) {
        setter(value);
    }
    return <Slider 
        className="w-50 mt-4 m-auto" 
        defaultValue={1} 
        step={1} 
        marks 
        min={1} 
        max={10}
        onChange={getValue}

  />
    
}